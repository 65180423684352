.page {
  height: 100vh;
  width: 100vw;
  background-color: #1C85BF;
  position: relative;
  .formContainer {
    width: 410px;
    height: 572px;
    background: #ffffff;
    box-shadow: 1px 2px 60px 12px rgba(197, 220, 232, 0.38);
    padding: 42px;
    // margin-top: 200px;
    margin-top: calc(calc(100vh - 44px - 10px - 572px) / 2);
    margin-left: 50%;
    transform: translateX(-205px);
    position: absolute;
    border-radius: 18px;
    form {
      margin-top: 16px;
    }
  }
  .tip {
    text-align: center;
    font-size: 12px;
    color: #999999;
  }
}

.footer {
  background-color: transparent;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  bottom: 0px;
  width: 100%;
  text-align: center;
  margin-bottom: 32px;
}
