.addClassPage {
    overflow: auto;
}

.filter {
  display: flex;
  :global(.ant-form-item-label) {
    margin-left: 12px;
  }
  :global(.ant-select) {
    width: 180px;
  }
}
.timeline {
  display: flex;
  flex-direction: column;
}
.timelineItem {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 26px;
//   margin-right: 12px;
  :global(.num) {
    width: 30px;
    height: 30px;
    background: #ffffff;
    border-radius: 15px;
    border: 2px solid #1C85BF;
    text-align: center;
    line-height: 28px;
    margin-right: 8px;
    
  }
  :global(.line) {
    width: 2px;
    background-color: #1C85BF;
    height: 26px;
    position: absolute;
    bottom: -26px;
    left: 15px;

  }
}
.table {
    :global(.ant-table-row.is-conflicted) {
        color: red;
    }
}

